<template>
	<Transition name="slide-down">
		<div
			class="tn-progress-circular"
			:class="`${progressClass} ${classList}`"
			role="progressbar"
			aria-valuemin="0"
			aria-valuemax="100"
			:aria-valuenow="modelValue"
			:style="setSize"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				:viewBox="`${diameter} ${diameter} ${2 * diameter} ${2 * diameter}`"
				:style="`transform: rotate(${rotation}deg); max-height: 100%; max-width: 100%;`"
			>
				<circle
					v-if="!indeterminate"
					class="tn-progress-circular__background"
					:r="radius"
					:stroke-width="strokeWidth"
					:stroke-dashoffset="0"
					:stroke-dasharray="circumference"
					:cx="2 * diameter"
					:cy="2 * diameter"
					:style="backgroundStyle"
					fill="transparent"
				></circle>
				<circle
					v-if="!indeterminate"
					class="tn-progress-circular__buffer"
					:r="radius"
					:stroke-width="strokeWidth"
					:stroke-dashoffset="buffer"
					:stroke-dasharray="circumference"
					:cx="2 * diameter"
					:cy="2 * diameter"
					:style="bufferStyle"
					fill="transparent"
				></circle>
				<circle
					class="tn-progress-circular__overlay"
					:r="radius"
					:stroke-width="strokeWidth"
					:stroke-dashoffset="progress"
					:stroke-dasharray="circumference"
					:cx="2 * diameter"
					:cy="2 * diameter"
					:style="progressStyle"
					fill="transparent"
				></circle>
			</svg>
			<div
				v-if="$slots['default']"
				class="progress-label"
			>
				<slot />
			</div>
		</div>
	</Transition>
</template>
<script>
import { defineComponent } from "vue";

import variables from "@/assets/tokens/json/variables.json";
const MAGIC_RADIUS_CONSTANT = 20;
const CIRCUMFERENCE = 2 * Math.PI * MAGIC_RADIUS_CONSTANT;

/***
 * The TnProgressCircular component is used to convey data visually to users. They can also represent an indeterminate amount, such as loading or processing.
 * Example use-cases include showing mobile data usage or point summary of TV subscription.
 * @displayName TnProgressCircular
 */
export default defineComponent({
	name: "TnProgressCircular",

	props: {
		/**
		 * Absolute positioning
		 */
		absolute: { type: Boolean, default: false },
		/**
		 * The value of the buffer
		 */
		bufferValue: { type: Number, default: 0 },
		/**
		 * Sets the color scheme.
		 * Choose between 'grey' and 'blue' (will be affected by theme)
		 * or choose custom from all available colors
		 */
		color: { type: String, default: "blue" },
		/**
		 * Change the color of the buffer
		 * Affected by theme
		 * or choose custom
		 */
		feedback: { type: String, default: "info" },
		/**
		 * 	Constantly animates, use when loading progress is unknown.
		 */
		indeterminate: { type: Boolean, default: false },
		/**
		 * Reverse the direction of the animation
		 */
		reverse: { type: Boolean, default: false },
		/**
		 * Rotate edg.
		 */
		rotate: { type: Number, default: 270 },
		/**
		 * Rounded edges on the bar, progress and buffer.
		 */
		rounded: { type: Boolean },
		/**
		 * Sets the height of the progress bar
		 * Use "height" to override
		 */
		size: { type: String, default: "l" },
		/**
		 * Sets the width of the stroke
		 * Will override the stroke set by size prop
		 */
		stroke: { type: Number, default: 2.5 },
		/**
		 * Sets the theme.
		 * Default colors will change acoording to theme.
		 */
		theme: { type: String, default: "white" },
		/**
		 * Changes behaviour
		 */
		threshold: { type: Boolean, default: false },
		/**
		 * The value of progress
		 */
		modelValue: { type: Number, default: 0 },
		/**
		 * Sets the width of the wrapping container to whatever the parent container is.
		 * This will override the size.
		 */
		block: { type: Boolean, default: false },
	},

	computed: {
		setSize() {
			if (this.block) {
				return "width: 100%; height: 100%; max-height: 100%; max-width: 100%;";
			} else {
				switch (this.size) {
					case "s":
						return "width: 60px; height: 60px;";
					case "m":
						return "width: 80px; height: 80px;";
					case "l":
						return "width: 100px; height: 100px;";
				}
			}
		},
		setStroke() {
			if (Math.abs(this.stroke)) {
				return Math.abs(this.stroke);
			}
			switch (this.size) {
				case "s":
					return 4;
				case "m":
					return 3;
				case "l":
					return 2.5;
			}
		},
		progressStyle() {
			return this.customColor ? `stroke: ${this.customColor};` : "";
		},
		backgroundStyle() {
			return this.customColor ? `stroke: ${this.customColor}; opacity: 0.2;` : "";
		},
		bufferStyle() {
			return this.customColor ? `stroke: ${this.customColor}; opacity: 0.4;` : "";
		},
		customColor() {
			return ["blue", "grey"]?.some((color) => color === this.color)
				? false
				: variables[this.color.trim().toLowerCase()];
		},
		rotation() {
			return this.indeterminate ? 0 : this.rotate;
		},
		radius() {
			return MAGIC_RADIUS_CONSTANT;
		},
		diameter() {
			return MAGIC_RADIUS_CONSTANT / (1 - this.setStroke / MAGIC_RADIUS_CONSTANT);
		},
		strokeWidth() {
			return (this.setStroke / MAGIC_RADIUS_CONSTANT) * this.diameter;
		},
		progress() {
			if (this.threshold) {
				if (this.thresholdReached) {
					return CIRCUMFERENCE * (1 - this.bufferValue);
				} else {
					return CIRCUMFERENCE * (1 - this.modelValue);
				}
			} else {
				return CIRCUMFERENCE * (1 - this.modelValue);
			}
		},
		buffer() {
			if (this.threshold) {
				if (this.thresholdReached) {
					return CIRCUMFERENCE * (1 - this.modelValue);
				} else {
					return CIRCUMFERENCE * (1 - this.modelValue);
				}
			} else {
				return CIRCUMFERENCE * (1 - this.bufferValue);
			}
		},
		classList() {
			return [
				...(this.rounded ? [`tn-progress-circular__rounded`] : []),
				...(this.absolute ? ["tn-progress-circular__absolute"] : []),
				...(this.reverse ? ["tn-progress-circular__reverse"] : []),
				...(["blue", "grey"].some((color) => color === this.color) ? [this.color] : []),
				`theme-${this.theme}`,
				this.feedback,
			].join(" ");
		},
		circumference() {
			return CIRCUMFERENCE;
		},
		strokeDashOffset() {
			return this.circumference * (1 - this.modelValue);
		},
		progressClass() {
			return [`tn-progress-circular__${this.indeterminate ? "in" : ""}determinate `];
		},
		thresholdValue() {
			return this.threshold ? this.bufferValue : 0;
		},
		thresholdReached() {
			return this.modelValue >= this.thresholdValue;
		},
	},
});
</script>
<style lang="scss">
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/main" as *;
@use "./style/colors" as *;

.tn-progress-circular {
	box-sizing: border-box;
	position: relative;

	@include colors(linear);

	> svg {
		position: relative;
		overflow: hidden;
		background: transparent;
	}

	.progress-label {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.tn-progress-circular__rounded {
		.tn-progress-circular__overlay,
		.tn-progress-circular__buffer {
			stroke-linecap: round;
		}
	}

	&.tn-progress-circular__determinate {
		.tn-progress-circular__overlay,
		.tn-progress-circular__buffer {
			position: absolute;
			transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
			height: 100%;
		}
	}

	&.tn-progress-circular__indeterminate {
		&.tn-progress-circular__reverse {
			> svg {
				animation-direction: reverse;
			}

			.tn-progress-circular__overlay {
				animation-direction: reverse;
			}
		}

		> svg {
			animation: progress-circular-rotate 1.4s linear infinite;
		}

		.tn-progress-circular__overlay {
			height: inherit;
			transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
			position: relative;
			stroke-dasharray: 80, 200;
			stroke-dashoffset: 0;
			animation: progress-circular-dash 1.4s ease-in-out infinite;
		}
	}
}

.tn-progress-circular__overlay {
	position: relative;
	overflow: hidden;
	background: transparent;
	transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);

	@include colors(linear);

	.tn-progress-circular__determinate {
		position: absolute;
		transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
		height: 100%;
	}
}

@keyframes progress-circular-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -15px;
	}

	100% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes progress-circular-rotate {
	100% {
		transform: rotate(1turn);
	}
}
</style>
